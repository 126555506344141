// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDb8X7tZ0XHa_BGst0mLKqYzyuSqFOiJoo",
  authDomain: "vinzzenzz-com.firebaseapp.com",
  databaseURL: "https://vinzzenzz-com.firebaseio.com",
  projectId: "vinzzenzz-com",
  storageBucket: "vinzzenzz-com.appspot.com",
  messagingSenderId: "210963168337",
  appId: "1:210963168337:web:795f9e557fb9768a3b4c70",
  measurementId: "G-LM068KYYZL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

export { storage, auth };
