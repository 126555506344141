// src/components/Login.js
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";

const FIXED_EMAIL = "gilde@vinzzenzz.com"; // Replace with your fixed email

const Login = ({ onLoginSuccess }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, FIXED_EMAIL, password);
      onLoginSuccess();
    } catch (err) {
      console.error("Login fehlgeschlagen:", err);
      setError("Passwort falsch");
    }
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <h2 style={styles.heading}>Bitte Passwort eingeben</h2>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
          required
        />
        {error && <div style={styles.error}>{error}</div>}
        <button type="submit" style={styles.button}>
          Login
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#111",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    backgroundColor: "#222",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0,0,0,0.3)",
  },
  heading: {
    color: "#fff",
    marginBottom: "1rem",
    textAlign: "center",
  },
  input: {
    padding: "0.5rem",
    marginBottom: "1rem",
    borderRadius: "4px",
    border: "1px solid #444",
    fontSize: "16px",
  },
  button: {
    padding: "0.5rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#1db954",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    marginBottom: "1rem",
    textAlign: "center",
  },
};

export default Login;
