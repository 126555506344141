// src/components/PlaylistGrid.js

import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import "./styles.css"; // Importing the CSS file

const PlaylistGrid = ({ playlists, openStory }) => { // Receive openStory as a prop
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
        <div style={styles.leftHeader}>
          <img src={logo} alt="Gildify Logo" style={styles.logoImage} /> {/* Logo */}
          <h1 style={styles.title}>Gildify</h1>
        </div>
        <div style={styles.wrappedButton}>Wrapped</div>
      </div>

      {/* Playlist Grid */}
      <div style={styles.gridContainer}>
        {playlists.map((playlist) => (
          <div
            key={playlist.id}
            onClick={() => navigate(`/playlist/${playlist.id}`)}
            style={styles.playlistCard}
          >
            <img
              src={playlist.cover}
              alt={`${playlist.name} cover`}
              style={styles.coverImage}
            />
            <h3 style={styles.playlistName}>{playlist.name}</h3>
          </div>
        ))}
      </div>

      {/* Big Button */}
      <div className="big-button-container">
        <div className="big-button" onClick={openStory}> {/* Trigger openStory */}
          <div className="big-button-text">Wrapped 2024</div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#121212",
    minHeight: "100vh",
    padding: "20px",
    color: "#b3b3b3",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  leftHeader: {
    display: "flex",
    alignItems: "center",
  },
  logoImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#fff",
  },
  wrappedButton: {
    backgroundColor: "#1db954",
    color: "#212121",
    fontSize: "14px",
    padding: "5px 15px",
    borderRadius: "20px",
    textAlign: "center",
    fontWeight: "normal",
    cursor: "pointer",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "15px",
    justifyContent: "center",
  },
  playlistCard: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "4px",
    backgroundColor: "#212121",
    color: "#b3b3b3",
    padding: "0px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
  },
  coverImage: {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "4px",
    marginRight: "10px",
  },
  playlistName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#fff",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default PlaylistGrid;
