// src/components/Player.js
import React, { useRef, useEffect, forwardRef } from "react";
import PlayIcon from "../assets/play.svg";
import PauseIcon from "../assets/pause.svg";
import StepBackwardIcon from "../assets/step-backward.svg";
import StepForwardIcon from "../assets/step-forward.svg";
import ArrowDownIcon from "../assets/arrow-down.svg";

/**
 * formatTime: Converts seconds to "mm:ss" format
 */
const formatTime = (seconds) => {
  if (!Number.isFinite(seconds)) return "0:00";
  const wholeSeconds = Math.floor(seconds);
  const mm = Math.floor(wholeSeconds / 60);
  const ss = wholeSeconds % 60;
  return `${mm}:${ss < 10 ? "0" + ss : ss}`;
};

const Player = forwardRef(({
  currentTrack,
  isPlaying,             // Controlled playback state
  togglePlayPause,      // Function to toggle play/pause
  onPlayNext,
  onPlayPrev,
  playlistTitle = "",
  onTrackEnd,
  isFullscreen,          // Fullscreen state
  setIsFullscreen,       // Function to set fullscreen state
  onPlayStateChange,    // Function to communicate play state changes
}, ref) => {
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);

  const mediaRef = useRef(null);

  // Expose seek function via ref if needed
  React.useImperativeHandle(ref, () => ({
    seekTo: (percent) => {
      if (mediaRef.current && duration) {
        mediaRef.current.currentTime = (percent / 100) * duration;
      }
    },
  }));

  // =============== Effect to Update Media Source ===============
  useEffect(() => {
    if (currentTrack && mediaRef.current) {
      mediaRef.current.src = currentTrack.url;
      mediaRef.current.load();
      if (isPlaying) {
        mediaRef.current.play().catch((err) => {
          console.error("Error playing track:", err);
        });
      }
    }
  }, [currentTrack]);

  // =============== Effect to Control Playback ===============
  useEffect(() => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.play().catch((err) => {
          console.error("Error playing track:", err);
        });
      } else {
        mediaRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleTimeUpdate = () => {
    if (!mediaRef.current) return;
    const currentTime = mediaRef.current.currentTime || 0;
    const dur = mediaRef.current.duration || 0;
    setDuration(dur);

    const ratio = dur > 0 ? (currentTime / dur) * 100 : 0;
    setProgress(Number.isFinite(ratio) ? ratio : 0);
  };

  const handleSeek = (e) => {
    if (!mediaRef.current) return;
    const newPercent = parseFloat(e.target.value) || 0;
    const newTime = (newPercent / 100) * duration;
    mediaRef.current.currentTime = newTime;
    setProgress(newPercent);
  };

  const toggleFullscreenHandler = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleTrackEnd = () => {
    if (onTrackEnd) {
      onTrackEnd();
    }
  };

  // Current time & time left
  const currentTimeSec = (progress / 100) * duration;
  const timeElapsed = formatTime(currentTimeSec);
  const timeRemaining = formatTime(duration - currentTimeSec);

  return (
    <div style={isFullscreen ? styles.fullscreenContainer : styles.smallContainer}>
      {/* 
        We use a <video> here, but "playsInline" and "disablePictureInPicture" 
        prevent iOS from opening the default player. 
      */}
      <video
        ref={mediaRef}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleTrackEnd}
        style={isFullscreen ? styles.videoFullscreen : styles.videoHidden}
        controls={false}
        muted={false}
        playsInline
        disablePictureInPicture
        disableRemotePlayback
      />

      {/* ======== Small (bottom) player ======== */}
      {!isFullscreen && currentTrack && (
        <>
          <div style={styles.smallPlayerRow}>
            {/* Cover + track info => opens fullscreen */}
            <div onClick={toggleFullscreenHandler} style={styles.leftSection}>
              <img
                src={currentTrack.cover}
                alt="Track Cover"
                style={styles.coverImage}
              />
              <div style={styles.trackDetails}>
                <div style={styles.trackTitle}>{currentTrack.name}</div>
                <div style={styles.trackArtist}>{currentTrack.interpret_name}</div>
              </div>
            </div>

            <div style={styles.rightSection}>
              <button onClick={togglePlayPause} style={styles.iconButton} aria-label={isPlaying ? "Pause" : "Play"}>
                <img
                  src={isPlaying ? PauseIcon : PlayIcon}
                  alt="Play/Pause"
                  style={styles.icon}
                />
              </button>
            </div>
          </div>

          {/* Small progress bar */}
          <div style={styles.smallProgressContainer}>
            <div
              style={{
                ...styles.smallProgressBar,
                width: `${progress}%`,
              }}
            />
          </div>
        </>
      )}

      {/* ======== Fullscreen player ======== */}
      {isFullscreen && currentTrack && (
        <>
          {/* 
            DUAL GRADIENT OVERLAY: 
            Black at top (0-20%), transparent in the middle (20-80%), black again (80-100%)
          */}
          <div style={styles.overlay} />

          {/* Header row: arrow (close fullscreen) + playlist title */}
          <div style={styles.fullscreenHeader}>
            <button onClick={toggleFullscreenHandler} style={styles.iconButton} aria-label="Exit Fullscreen">
              <img src={ArrowDownIcon} alt="Exit Fullscreen" style={styles.icon} />
            </button>
            <span style={styles.playlistTitle}>{playlistTitle}</span>
          </div>

          <div style={styles.fullscreenContent}>
            {/* Cover + title + artist row */}
            <div style={styles.coverTitleRow}>
              <img
                src={currentTrack.cover}
                alt="Cover"
                style={styles.smallCoverFullscreen}
              />
              <div style={styles.titleArtistBlock}>
                <div style={styles.titleLine}>{currentTrack.name}</div>
                <div style={styles.artistLine}>{currentTrack.interpret_name}</div>
              </div>
            </div>

            {/* Seek bar */}
            <input
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={handleSeek}
              style={styles.whiteSeekBar}
              aria-label="Seek Bar"
            />

            <div style={styles.timeInfoRow}>
              <span style={styles.timeElapsed}>{timeElapsed}</span>
              <span style={styles.timeRemaining}>-{timeRemaining}</span>
            </div>

            {/* Bottom controls: Prev / PlayPause / Next */}
            <div style={styles.fullscreenControls}>
              <button onClick={onPlayPrev} style={styles.iconButton} aria-label="Previous Track">
                <img
                  src={StepBackwardIcon}
                  alt="Previous"
                  style={styles.smallIcon}
                />
              </button>

              <button onClick={togglePlayPause} style={styles.iconButton} aria-label={isPlaying ? "Pause" : "Play"}>
                <img
                  src={isPlaying ? PauseIcon : PlayIcon}
                  alt="Play/Pause"
                  style={styles.largeIcon}
                />
              </button>

              <button onClick={onPlayNext} style={styles.iconButton} aria-label="Next Track">
                <img
                  src={StepForwardIcon}
                  alt="Next"
                  style={styles.smallIcon}
                />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const styles = {
  // ----- SMALL (bottom) PLAYER -----
  smallContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#181818",
    color: "#fff",
    zIndex: 9999,
    height: "64px",
    display: "flex",
    flexDirection: "column",
    userSelect: "none", // Prevent text selection
  },
  smallPlayerRow: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    flex: 1,
    userSelect: "none", // Prevent text selection
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none", // Prevent text selection
  },
  coverImage: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    objectFit: "cover",
    marginRight: "10px",
    userSelect: "none", // Prevent text selection
  },
  trackDetails: {
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    userSelect: "none", // Prevent text selection
  },
  trackTitle: {
    fontSize: "14px",
    color: "#ffffff", // Set to white
    lineHeight: "16px",
    marginBottom: "2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    userSelect: "none", // Prevent text selection
  },
  trackArtist: {
    fontSize: "12px",
    color: "#cccccc", // Lighter color for interpret name
    lineHeight: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    userSelect: "none", // Prevent text selection
  },
  rightSection: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    background: "none",
    border: "none",
    padding: "6px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    userSelect: "none", // Prevent text selection
  },
  icon: {
    width: "30px",
    height: "30px",
  },
  smallProgressContainer: {
    width: "100%",
    height: "4px",
    backgroundColor: "#404040",
    position: "relative",
    userSelect: "none", // Prevent text selection
  },
  smallProgressBar: {
    height: "100%",
    backgroundColor: "#ffffff", // Ensure progress bar is white
    transition: "width 0.1s linear",
  },

  // ----- FULLSCREEN PLAYER -----
  fullscreenContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#000000",
    color: "#ffffff", // Set default text color to white
    zIndex: 10000,
    overflow: "hidden",
    userSelect: "none", // Prevent text selection
  },
  videoFullscreen: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  videoHidden: {
    width: 0,
    height: 0,
    visibility: "hidden",
  },

  // DUAL GRADIENT OVERLAY: 
  // Black at top (0-20%), transparent in the middle (20-80%), black again (80-100%)
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: `
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.8) 100%
      )
    `,
    // Prevent overlay from intercepting pointer events
    pointerEvents: "none",
  },

  fullscreenHeader: {
    position: "absolute",
    top: "10px",
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    zIndex: 10,
    userSelect: "none", // Prevent text selection
  },
  playlistTitle: {
    marginLeft: "20px",
    fontSize: "16px",
    fontWeight: 500,
    opacity: 1,
    zIndex: 10,
    color: "#ffffff", // Ensure playlist title is white
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    userSelect: "none", // Prevent text selection
  },
  fullscreenContent: {
    position: "absolute",
    left: "5%",
    right: "5%",
    bottom: "20px",
    zIndex: 11,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none", // Prevent text selection
  },
  coverTitleRow: {
    alignSelf: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    zIndex: 11,
    alignItems: "center",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
    marginBottom: "8px",
    userSelect: "none", // Prevent text selection
  },
  smallCoverFullscreen: {
    width: "48px",
    height: "48px",
    objectFit: "cover",
    borderRadius: "4px",
    marginRight: "10px",
    userSelect: "none", // Prevent text selection
  },
  titleArtistBlock: {
    display: "flex",
    flexDirection: "column",
    userSelect: "none", // Prevent text selection
  },
  titleLine: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "4px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "220px",
    color: "#ffffff", // Ensure title is white
    userSelect: "none", // Prevent text selection
  },
  artistLine: {
    fontSize: "14px",
    color: "#cccccc", // Lighter color for interpret name
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "220px",
    userSelect: "none", // Prevent text selection
  },
  whiteSeekBar: {
    WebkitAppearance: "none",
    appearance: "none",
    background: "#ffffff",
    height: "2px",
    borderRadius: "1px",
    outline: "none",
    cursor: "pointer",
    margin: "10px 0",
    width: "100%",
  },
  timeInfoRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
    color: "#b3b3b3",
    width: "100%",
    maxWidth: "100%",
    marginBottom: "16px",
    userSelect: "none", // Prevent text selection
  },
  timeElapsed: {},
  timeRemaining: {},
  fullscreenControls: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "30px",
    userSelect: "none", // Prevent text selection
  },
  smallIcon: {
    width: "24px",
    height: "24px",
  },
  largeIcon: {
    width: "55px",
    height: "55px",
  },
};

export default Player;
