// src/components/WelcomeScreen.js
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.svg"; // Ensure the correct path to your logo

const WelcomeScreen = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger the animation after the component mounts
    const animationTimer = setTimeout(() => {
      setAnimate(true);
    }, 3000); // Start animation immediately

    return () => clearTimeout(animationTimer);
  }, []);

  return (
    <div
      style={{
        ...styles.container,
        animation: animate ? "zoomFadeOut 1s forwards" : "none",
      }}
    >
      {/* Embedded CSS for keyframe animations */}
      <style>
        {`
          @keyframes zoomFadeOut {
            0% {
              opacity: 1;
              transform: scale(1);
            }
            100% {
              opacity: 0;
              transform: scale(1.4);
            }
          }
        `}
      </style>

      <img src={logo} alt="Gildify Logo" style={styles.logo} />
      <h1 style={styles.message}>Gildify</h1>
    </div>
  );
};

// Inline Styles
const styles = {
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#121212", // Consistent with LoadingScreen
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10001, // Higher than LoadingScreen
    color: "#FFFFFF", // Spotify green accent
    //fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    transition: "opacity 1s ease-in-out, transform 1s ease-in-out",
  },
  logo: {
    width: "120px",
    height: "120px",
    marginBottom: "20px",
  },
  message: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    margin: 0,
  },
};

export default WelcomeScreen;
