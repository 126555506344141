// src/App.js

import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import Stories from 'react-insta-stories'; // Import the Stories component
import { fetchPlaylists } from "./data/fetchPlaylists";
import PlaylistGrid from "./components/PlaylistGrid";
import PlaylistPage from "./components/PlaylistPage";
import Player from "./components/Player";
import LoadingScreen from "./components/LoadingScreen";
import WelcomeScreen from "./components/WelcomeScreen";
import ErrorBoundary from "./components/ErrorBoundary";
import Login from "./components/Login";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebaseConfig";

// For demo, toggles shorter or no load times
const DEV_MODE = false;

const App = () => {
  const [playlists, setPlaylists] = useState([]);
  const [error, setError] = useState(null);

  // Which track is currently playing
  const [currentTrack, setCurrentTrack] = useState(null);

  // Loading state
  const [loading, setLoading] = useState(true);
  const [fetchComplete, setFetchComplete] = useState(false);

  // Playback state
  const [isPlaying, setIsPlaying] = useState(false);

  // Fullscreen state
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Ref to Player component
  const playerRef = useRef(null);

  // Authentication state
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // New state for progress
  const [progress, setProgress] = useState(0);

  // New state for WelcomeScreen
  const [showWelcome, setShowWelcome] = useState(false);

  // New state for Story
  const [showStory, setShowStory] = useState(false);

  // Define the stories content
  const storiesContent = [
    {
      url: '/assets/stories/story-1.mp4',
      type: 'video',
    },
    {
      url: '/assets/stories/story-2.mp4',
      type: 'video',
    },
    {
      url: '/assets/stories/story-3.mp4',
      type: 'video',
    },
    {
      url: '/assets/stories/story-4.mp4',
      type: 'video',
    },
    {
      url: '/assets/stories/story-5.mp4',
      type: 'video',
    },
    {
      url: '/assets/stories/story-6.mp4',
      type: 'video',
    },
    {
      url: '/assets/stories/story-7.mp4',
      type: 'video',
    },
    // Add more stories as needed
  ];

  // Function to open the story
  const openStory = () => {
    // Stop any currently playing tracks
    setIsPlaying(false);
    setCurrentTrack(null);
    setIsFullscreen(false);

    // Show the story
    setShowStory(true);
  };

  // =============== Fetch Playlists on Mount ===============
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    const loadPlaylists = async () => {
      if (!isAuthenticated) return;
      setLoading(true);
      setProgress(0); // Reset progress at the start
      try {
        const playlistsData = await fetchPlaylists(
          setProgress, // Pass setProgress to update loading screen
          setError
        );
        setPlaylists(playlistsData);
        setFetchComplete(true);
      } catch (err) {
        console.warn("[App] Error in loadPlaylists:", err);
        setError("Failed to load playlists.");
      } finally {
        setLoading(false);
        setShowWelcome(true); // Trigger the WelcomeScreen after loading
      }
    };
    loadPlaylists();

    // Cleanup authentication listener on unmount
    return () => {
      unsubscribeAuth();
    };
  }, [isAuthenticated]);

  // =============== Handle Welcome Screen Transition ===============
  useEffect(() => {
    let welcomeTimer;
    if (showWelcome) {
      welcomeTimer = setTimeout(() => {
        setShowWelcome(false); // Hide WelcomeScreen after 4 seconds
      }, 4000);
    }
    return () => {
      if (welcomeTimer) clearTimeout(welcomeTimer);
    };
  }, [showWelcome]);

  // =============== Find the Playlist for a Given Track ===============
  const findTrackPlaylist = (trackId) => {
    return playlists.find((playlist) =>
      playlist.titles.some((title) => title.id === trackId)
    );
  };

  // =============== Play a Track (handles "restart if same track") ===============
  const playTrack = (track) => {
    if (!track) return;

    // If the track clicked is already playing, restart it
    if (currentTrack && track.id === currentTrack.id) {
      setCurrentTrack(null);
      setTimeout(() => setCurrentTrack(track), 0);
    } else {
      setCurrentTrack(track);
    }

    // Start playback and enable fullscreen
    setIsPlaying(true);
    setIsFullscreen(true);
  };

  // =============== Next Track (cyclical) ===============
  const playNextTrack = () => {
    if (!currentTrack) return;

    const playlist = findTrackPlaylist(currentTrack.id);
    if (!playlist || !playlist.titles?.length) return;

    const total = playlist.titles.length;
    const idx = playlist.titles.findIndex((t) => t.id === currentTrack.id);
    if (idx < 0) return;

    // If we're at the last track, wrap around to first
    const nextIndex = (idx + 1) % total;
    setCurrentTrack(playlist.titles[nextIndex]);

    // Ensure playback is started and maintain fullscreen
    setIsPlaying(true);
  };

  // =============== Prev Track (cyclical) ===============
  const playPrevTrack = () => {
    if (!currentTrack) return;

    const playlist = findTrackPlaylist(currentTrack.id);
    if (!playlist || !playlist.titles?.length) return;

    const total = playlist.titles.length;
    const idx = playlist.titles.findIndex((t) => t.id === currentTrack.id);
    if (idx < 0) return;

    // If we're at the first track, wrap around to last
    const prevIndex = (idx - 1 + total) % total;
    setCurrentTrack(playlist.titles[prevIndex]);

    // Ensure playback is started and maintain fullscreen
    setIsPlaying(true);
    setIsFullscreen(true);
  };

  // =============== Play the Entire Playlist (Play Button) ===============
  const playWholePlaylist = (playlistId) => {
    if (!playlistId) return;
    const playlist = playlists.find((p) => p.id === playlistId);
    if (!playlist || !playlist.titles?.length) {
      setCurrentTrack(null);
      setIsPlaying(false);
      setIsFullscreen(false);
      return;
    }
    // Pick the first track in the playlist
    setCurrentTrack(playlist.titles[0]);

    // Ensure playback is started and enable fullscreen
    setIsPlaying(true);
    setIsFullscreen(true);
  };

  // =============== Toggle Play/Pause ===============
  const togglePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      if (currentTrack) {
        setIsPlaying(true);
      } else {
        // If no track is playing, start the first playlist (optional behavior)
        if (playlists.length > 0) {
          playWholePlaylist(playlists[0].id);
        }
      }
    }
    // Note: Do not change isFullscreen when toggling from small player
  };

  // =============== Handle Play State Changes from Player ===============
  const handlePlayStateChange = (playing) => {
    setIsPlaying(playing);
  };

  // =============== Router: PlaylistPage Wrapper (handles back button) ===============
  const PlaylistPageWrapper = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const playlist = playlists.find((p) => p.id === id);

    const handleBack = () => {
      navigate(-1);
    };

    return (
      <PlaylistPage
        playlist={playlist}
        onPlayTrack={playTrack}
        onPlayPlaylist={playWholePlaylist}
        onBack={handleBack}
        isPlaying={isPlaying}
        togglePlayPause={togglePlayPause}
      />
    );
  };

  // =============== Which playlist is currently playing (for display) ===============
  const currentPlaylist = currentTrack
    ? findTrackPlaylist(currentTrack.id)
    : null;
  const currentPlaylistName = currentPlaylist ? currentPlaylist.name : "";

  // =============== Render ===============
  if (!isAuthenticated) {
    return <Login onLoginSuccess={() => setIsAuthenticated(true)} />;
  }

  return (
    <ErrorBoundary>
      <Router>
        <div
          style={{
            minHeight: "100vh",
            background: "#111",
            position: "relative",
            paddingBottom: isFullscreen ? 0 : "64px", // Prevent content from being hidden behind the player
            overflow: showStory ? 'hidden' : 'auto', // Prevent scrolling when story is active
          }}
        >
          {error && (
            <div style={{ color: "red", padding: "1rem" }}>
              <strong>Error:</strong> {error}
            </div>
          )}

          {loading && !error && (
            <LoadingScreen progress={progress} /> // Pass progress to LoadingScreen
          )}

          {!loading && !error && showWelcome && <WelcomeScreen />} {/* Welcome Screen */}

          {!loading && !error && !showWelcome && (
            <>
              <Routes>
                <Route
                  path="/"
                  element={<PlaylistGrid playlists={playlists} openStory={openStory} />} // Pass openStory
                />
                <Route path="/playlist/:id" element={<PlaylistPageWrapper />} />
              </Routes>

              {/* Bottom (or fullscreen) Player */}
              <Player
                ref={playerRef}
                currentTrack={currentTrack}
                isPlaying={isPlaying}
                togglePlayPause={togglePlayPause}
                onPlayNext={playNextTrack}
                onPlayPrev={playPrevTrack}
                playlistTitle={currentPlaylistName}
                onTrackEnd={playNextTrack}
                isFullscreen={isFullscreen}
                setIsFullscreen={setIsFullscreen}
                onPlayStateChange={handlePlayStateChange} // Receive play state changes
              />

            </>
          )}

          {/* Render the Stories component as a fullscreen overlay */}
          {showStory && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 1000, // Ensure it's on top of other elements
              backgroundColor: 'black', // Optional: background color
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {/* Close Button */}
              <button
                onClick={() => setShowStory(false)}
                style={{
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  background: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  fontSize: '24px',
                  cursor: 'pointer',
                  zIndex: 1001, // Above the Stories component
                }}
                aria-label="Close Stories"
              >
                &times;
              </button>
              <Stories
                stories={storiesContent}
                defaultInterval={5000} // Duration for each story in milliseconds
                width={window.innerWidth}
                height={window.innerHeight}
                onClose={() => setShowStory(false)} // Handle story close
                onAllStoriesEnd={() => setShowStory(false)} // Handle all stories end
                loop={false} // Disable looping
              />
            </div>
          )}
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
