// src/components/PlaylistPage.js
import React from "react";
import { FaPlay, FaPause } from "react-icons/fa"; // Import FaPause
import { ReactComponent as BackIcon } from "../assets/arrow-down.svg"; // Rotated to left

const PlaylistPage = ({
  playlist,
  onPlayTrack,
  onPlayPlaylist,
  onBack,
  isPlaying,           // Indicates if playback is ongoing
  togglePlayPause,    // Function to toggle play/pause
}) => {
  if (!playlist) {
    return <div style={{ color: "white", padding: "1rem", userSelect: "none" }}>Playlist not found.</div>;
  }

  const isEmpty = !playlist.titles || playlist.titles.length === 0;

  return (
    <div style={styles.container}>
      {/* Header Section with zoomed-in background */}
      <div style={styles.headerSection}>
        <div
          style={{
            ...styles.headerImage,
            backgroundImage: `url(${playlist.cover})`,
          }}
        />
        {/* Dark overlay on top of the background */}
        <div style={styles.darkOverlay} />

        {/* Back button (rotated arrow) */}
        <button style={styles.backButton} onClick={onBack} aria-label="Go Back">
          <BackIcon style={styles.backIcon} />
        </button>

        {/* Playlist Title (left-aligned) */}
        <h1 style={styles.playlistName}>{playlist.name}</h1>
      </div>

      {/* Description below the header */}
      <div style={styles.descriptionContainer}>
        <p style={styles.playlistDescription}>{playlist.description}</p>
      </div>

      {/* Action row: Play/Pause button */}
      <div style={styles.actionRow}>
        <button
          style={{
            ...styles.playButton,
            backgroundColor: isPlaying ? "#e0245e" : "#1db954", // Change color when playing
          }}
          onClick={() => {
            if (isPlaying) {
              togglePlayPause(); // Pause playback
            } else {
              onPlayPlaylist(playlist.id); // Start playback and enable fullscreen
            }
          }}
          disabled={isEmpty && !isPlaying} // Disable if empty and not playing
          aria-label={isPlaying ? "Pause Playlist" : "Play Playlist"}
        >
          {isPlaying ? <FaPause /> : <FaPlay />} {/* Toggle icon */}
        </button>
      </div>

      {isEmpty && !isPlaying ? ( // Show "empty" message only if not playing
        <div style={{ padding: "1rem", userSelect: "none" }}>This playlist is empty.</div>
      ) : (
        <ul style={styles.trackList}>
          {playlist.titles.map((title) => (
            <li
              key={title.id}
              style={styles.trackItem}
              onClick={() => onPlayTrack(title)}
              aria-label={`Play ${title.name} by ${title.interpret_name}`}
            >
              <img
                src={title.cover}
                alt={`${title.name} cover`}
                style={styles.trackCover}
              />
              <div style={styles.trackInfo}>
                <span style={styles.trackName}>{title.name}</span>
                <span style={styles.trackArtist}>{title.interpret_name}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    backgroundColor: "#121212",
    color: "white",
    overflow: "hidden",
    userSelect: "none", // Prevent text selection
  },
  headerSection: {
    position: "relative",
    height: "25vh",
    width: "100%",
    overflow: "hidden",
    userSelect: "none", // Prevent text selection
  },
  headerImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundPosition: "center",
    backgroundSize: "120%",
    backgroundRepeat: "no-repeat",
    userSelect: "none", // Prevent text selection
  },
  darkOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.4)",
    userSelect: "none", // Prevent text selection
  },
  backButton: {
    position: "absolute",
    top: "16px",
    left: "16px",
    background: "none",
    border: "none",
    cursor: "pointer",
    zIndex: 2,
    userSelect: "none", // Prevent text selection
  },
  backIcon: {
    transform: "rotate(90deg)",
    width: "24px",
    height: "24px",
    fill: "white",
    userSelect: "none", // Prevent text selection
  },
  playlistName: {
    position: "absolute",
    bottom: "16px",
    left: "16px",
    zIndex: 2,
    fontSize: "28px",
    fontWeight: "bold",
    margin: 0,
    padding: 0,
    color: "#ffffff", // Ensure playlist name is white
    userSelect: "none", // Prevent text selection
  },
  descriptionContainer: {
    padding: "16px",
    userSelect: "none", // Prevent text selection
  },
  playlistDescription: {
    margin: 0,
    fontSize: "16px",
    color: "#b3b3b3",
    userSelect: "none", // Prevent text selection
  },
  actionRow: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px 16px 16px",
    userSelect: "none", // Prevent text selection
  },
  playButton: {
    backgroundColor: "#1db954",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s ease",
    userSelect: "none", // Prevent text selection
  },
  trackList: {
    listStyle: "none",
    padding: "0 16px 16px",
    margin: 0,
    overflowY: "auto",
    flex: 1,
    userSelect: "none", // Prevent text selection
  },
  trackItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1c1c1c",
    borderRadius: "8px",
    marginBottom: "8px",
    padding: "8px",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
    userSelect: "none", // Prevent text selection
  },
  trackCover: {
    width: "50px",
    height: "50px",
    borderRadius: "4px",
    objectFit: "cover",
    marginRight: "12px",
    userSelect: "none", // Prevent text selection
  },
  trackInfo: {
    display: "flex",
    flexDirection: "column",
    userSelect: "none", // Prevent text selection
  },
  trackName: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#ffffff", // Ensure track name is white
    userSelect: "none", // Prevent text selection
  },
  trackArtist: {
    fontSize: "14px",
    color: "#cccccc", // Lighter color for interpret name
    userSelect: "none", // Prevent text selection
  },
};

export default PlaylistPage;
