// src/components/LoadingScreen.js

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const LoadingScreen = ({ progress }) => {
  const messages = [
    "Weinschorle wird vorbereitet...",
    "Zigarette wird geraucht...",
    "Bier wird geext...",
    "10 auf Verdacht werden bestellt...",
    "Unwritten wird gesungen...",
    "Drohnen werden versenkt...",
    "JAAAAAAAAA wird geschrien...",
    "Elotrans wird getrunken...",
    "Magdalena wird gerufen...",
    "Rosi auf Dauerschleife spielen...",
    "Waschbecken wird verstopft...",
    "Mit Sandys Hund (Götz) gassi gehen...",
    "Krankenhausbett wird vorbereitet..."
  ];

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const [shuffledMessages, setShuffledMessages] = useState(shuffleArray(messages));
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setFade(false); // Start fade-out

      setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => {
          if (prevIndex + 1 >= shuffledMessages.length) {
            // Reshuffle when all messages have been shown
            setShuffledMessages(shuffleArray(messages));
            return 0;
          } else {
            return prevIndex + 1;
          }
        });
        setFade(true); // Start fade-in
      }, 500); // Duration of fade-out (matches CSS transition)
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(messageInterval);
  }, [shuffledMessages, messages]);

  return (
    <div style={styles.loadingContainer}>
      {/* Embedded CSS for keyframe animations */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>

      <div style={styles.loadingContent}>
        {/* Spinner */}
        <div style={styles.spinner}></div>

        {/* Dynamic Message */}
        <div
          style={{
            ...styles.message,
            opacity: fade ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          {shuffledMessages[currentMessageIndex]}
        </div>
      </div>

      {/* Progress Bar with Percentage */}
      <div style={styles.progressBarContainer}>
        <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
      </div>

      {/* Display Progress Percentage */}
      <div style={styles.percentageText}>{progress}%</div>
    </div>
  );
};

LoadingScreen.propTypes = {
  progress: PropTypes.number.isRequired,
};

// Inline Styles
const styles = {
  loadingContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#121212", // Spotify-like dark background
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    color: "#1DB954", // Spotify green accent
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  loadingContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "40px",
  },
  spinner: {
    border: "8px solid #f3f3f3", // Light grey
    borderTop: "8px solid #1DB954", // Green
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
    marginBottom: "20px",
  },
  message: {
    fontSize: "18px",
    textAlign: "center",
    maxWidth: "80%",
  },
  progressBarContainer: {
    position: "relative",
    width: "80%",
    height: "20px",
    backgroundColor: "#333",
    borderRadius: "10px",
    overflow: "hidden",
    marginTop: "20px",
  },
  progressBar: {
    height: "100%",
    background: "#1DB954", // Spotify green
    transition: "width 0.5s ease-in-out",
  },
  percentageText: {
    marginTop: "10px",
    fontSize: "16px",
    color: "#1DB954",
  },
};

export default LoadingScreen;
