import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../firebaseConfig";

// Fetch and resolve URLs for all files in a folder
export const fetchFilesInFolder = async (folderPath) => {
  const folderRef = ref(storage, folderPath);
  const files = await listAll(folderRef);

  const fileUrls = await Promise.all(
    files.items.map(async (item) => {
      const url = await getDownloadURL(item);
      return { name: item.name, url };
    })
  );

  return fileUrls;
};
