import { fetchFilesInFolder } from "../utils/firebaseUtils";

const preloadContent = async (url, updateProgress) => {
  try {
    const response = await fetch(url, { mode: "cors" });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const blob = await response.blob();
    updateProgress();
    return URL.createObjectURL(blob); // Create a local Blob URL for the content
  } catch (err) {
    console.error(`Error preloading content from ${url}:`, err);
    updateProgress();
    return url; // Fallback to original URL if preloading fails
  }
};

export const fetchPlaylists = async (setProgress, setError) => {
  try {
    // Fetch playlists.json from public
    const response = await fetch("/playlists.json");
    const playlistsData = await response.json();

    // Fetch all files from Firebase storage
    const images = await fetchFilesInFolder("images");
    const videos = await fetchFilesInFolder("videos");
    const audio = await fetchFilesInFolder("audio");

    // Calculate total number of files to preload
    const totalFiles = playlistsData.playlists.reduce(
      (count, playlist) => count + 1 + playlist.titles.length * 2, // 1 for playlist cover, 1 for title video/audio, and 1 for title cover
      0
    );

    let loadedFiles = 0;
    const updateProgress = () => {
      loadedFiles += 1;
      setProgress(Math.round((loadedFiles / totalFiles) * 100));
    };

    // Map over playlists to enrich data with resolved and preloaded URLs
    const enrichedPlaylists = await Promise.all(
      playlistsData.playlists.map(async (playlist) => {
        const resolvedCover =
          images.find((img) => img.name === playlist.cover)?.url || playlist.cover;
        const preloadedCover = await preloadContent(resolvedCover, updateProgress);

        const resolvedTitles = await Promise.all(
          playlist.titles.map(async (title) => {
            // Resolve and preload the main URL (video/audio)
            const resolvedUrl =
              title.type === "video"
                ? videos.find((vid) => vid.name === title.url)?.url
                : audio.find((aud) => aud.name === title.url)?.url;

            const preloadedUrl = resolvedUrl
              ? await preloadContent(resolvedUrl, updateProgress)
              : resolvedUrl;

            const resolvedCoverUrl = images.find((img) => img.name === title.cover)?.url;
            const preloadedCoverImage = resolvedCoverUrl
              ? await preloadContent(resolvedCoverUrl, updateProgress)
              : null;

            return {
              ...title,
              url: preloadedUrl || title.url,
              cover: preloadedCoverImage || title.cover,
            };
          })
        );

        return { ...playlist, cover: preloadedCover, titles: resolvedTitles };
      })
    );

    return enrichedPlaylists;
  } catch (err) {
    console.error("Error fetching playlists:", err);
    setError(err.message);
    throw err;
  }
};
